import React from 'react';
import {Button, CircularProgress, makeStyles, useTheme} from "@material-ui/core";
import RouterLink from "Components/WrappedMUIComponents/RouterLink";
import classNames from "classnames";
import clsx from "clsx";

const useButtonStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        textTransform: 'none',
        fontWeight: theme.typography.button.fontWeight
    },
    spinner: {
        position: "absolute"
    },
    iconOnly: {
        lineHeight: 0,
        minWidth: 0,
        padding: theme.spacing(0.75)
    },
    fullWidth: {
        width: "100%"
    }
}));

export const BaseButton = ({isLoading = false, to = false, iconOnly = false, fullWidth = false, children, className, innerStyles = {}, onClick, ...rest}) => {
    const classes = useButtonStyles();

    if (isLoading) {
        onClick = null;
    }

    if (to) {
        const routerButtonClasses = classNames(
            classes.root,
            {
                [classes.iconOnly]: iconOnly,
                [classes.fullWidth]: fullWidth,
            }
        );

        return (
            <RouterLink to={to} className={className}>
                <Button {...rest} onClick={onClick} className={routerButtonClasses} style={innerStyles}>
                    {children}
                </Button>
            </RouterLink>
        );
    }

    const buttonClasses = classNames(
        classes.root,
        className,
        {
            [classes.iconOnly]: iconOnly,
            [classes.fullWidth]: fullWidth,
        }
    );

    return (
        <Button {...rest} onClick={onClick} className={buttonClasses}>
            <span style={{visibility: isLoading ? "hidden" : "visible"}}>{children}</span>
            {isLoading && <CircularProgress className={classes.spinner} color="inherit" size={24}/>}
        </Button>
    );
}

export const PrimaryButton = ({children, variant = "contained", strict = false, ...rest}) => {
    const theme = useTheme();

    return <BaseButton variant={variant} color={strict ? 'primary' : theme.overrides.Buttons.primary} {...rest}>{children}</BaseButton>
};

export const SecondaryButton = ({children, variant = "contained", strict = false, ...rest}) => {
    const theme = useTheme();
    const isStrict = strict && theme.overrides.Buttons.strict;

    return <BaseButton variant={variant} {...rest} color={strict ? 'secondary' : theme.overrides.Buttons.secondary}>{children}</BaseButton>
};

export const DefaultButton = ({children, variant = "contained", ...rest}) => {
    return <BaseButton variant={variant} {...rest}>{children}</BaseButton>
};

export const PlainButton = ({children, ...rest}) => {
    return <BaseButton {...rest}>{children}</BaseButton>
};

const usePlayerBarButtonStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.layout.main,
        color: theme.palette.layout.contrastText,

        '&:hover': {
            background: theme.palette.layout.dark
        }
    }
}));

export const PlayerBarButton = ({children, variant = "contained", className, ...rest}) => {
    const classes = usePlayerBarButtonStyles();

    return <BaseButton variant={variant} {...rest} className={clsx(classes.root, className)}>{children}</BaseButton>
};
