import React from "react";
import {PrimaryButton} from "Components/Button";
import {Box, Typography} from "@material-ui/core";
import {GenericPageContainer} from "Pages/GenericInfoPages/GenericPage";
import ErrorBoundary from "Components/ErrorBoundary";

const LoadableRouteLoading = () => (
    <div style={{height: "100vh"}}/>
);

export const ErrorRetry = props => {
    return (
        <Box textAlign="center">
            <Typography variant="h5" paragraph>Oops... Your connection seems off...</Typography>
            <Typography variant="h6">Please check your internet and try again.</Typography>
            <Box my={2}>
                <PrimaryButton onClick={props.retry}>Retry</PrimaryButton>
            </Box>
        </Box>
    );
};

const ErrorRetryPage = (props) => (
    <GenericPageContainer style={{width: "100%"}}>
        <Box textAlign="center">
            <ErrorRetry retry={props.retry}/>
        </Box>
    </GenericPageContainer>
);

export const LazyLoaderFactory = (promise, Loader = () => <LoadableRouteLoading/>) => {
    function LazyLoader(props) {
        const [loading, setLoading] = React.useState(true);
        const retry = React.useCallback(() => {
            setLoading(true);
            // setIsRetrying(true)
        }, []);
        // const [isRetrying, setIsRetrying] = React.useState(false);

        const RouteLoaderWithRetry = (lazyComponent, attemptsLeft) => {
            return new Promise((resolve, reject) => {
                lazyComponent()
                    // Debugger
                    // .then(response => {
                    //     if(attemptsLeft > 1) throw new Error();
                    //     // throw new Error();
                    //     return response;
                    // })
                    .then(response => {
                        // if(response.default.name === "PrimaryLayout" || isRetrying) {
                        //     resolve(response);
                        //     return;
                        // }
                        // console.log(lazyComponent)
                        // console.log(response);
                        // reject();

                        // Once we get a response from the lazyComponent, we can add the loaded
                        // class to the #app which will remove the loading overlay.
                        if (!document.getElementById('app').classList.contains('loaded')) {
                            document.getElementById('app').classList.add('loaded');
                        }

                        resolve(response);
                    })
                    .catch((error) => {
                        // let us retry after 1500 ms
                        setTimeout(() => {
                            if (attemptsLeft === 1) {
                                // reject(error);
                                return;
                            }
                            RouteLoaderWithRetry(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                        }, 1500);
                    });
            });
        }

        const Lazy = React.useMemo(() => React.lazy(() => RouteLoaderWithRetry(promise, 3).catch(() => {
            setLoading(false);
            return {default: () => <ErrorRetryPage retry={retry}/>};
        })), [promise, loading]);

        return <React.Suspense fallback={<Loader/>}><Lazy {...props}/></React.Suspense>;
    }

    return LazyLoader;
};

export const LazyLoad = ({component: Component, isRoute = true, ...rest}) => <ErrorBoundary isRoute><Component {...rest} /></ErrorBoundary>;

const Registration = LazyLoaderFactory(() => import(/* webpackChunkName: "Registration" */ 'Pages/register'));
export const LazyRegistration = props => <LazyLoad component={Registration} {...props} />;

const HomePage = LazyLoaderFactory(() => import(/* webpackChunkName: "Homepage" */ 'Pages/index'));
export const LazyHomePage = props => <LazyLoad component={HomePage} {...props} />;

const LoginPage = LazyLoaderFactory(() => import(/* webpackChunkName: "LoginPage" */ 'Pages/login'));
export const LazyLoginPage = props => <LazyLoad component={LoginPage} {...props} />;

const DesktopGamePage = LazyLoaderFactory(() => import(/* webpackChunkName: "DesktopGamePage" */ 'Pages/GamePages/desktopGame'));
export const LazyDesktopGamePage = props => <LazyLoad component={DesktopGamePage} {...props} />;

const MobileGamePage = LazyLoaderFactory(() => import(/* webpackChunkName: "MobileGamePage" */ 'Pages/GamePages/mobileGame'));
export const LazyMobileGamePage = props => <LazyLoad component={MobileGamePage} {...props} />;

const DesktopBingoGamePage = LazyLoaderFactory(() => import(/* webpackChunkName: "DesktopBingoGamePage" */ 'Pages/GamePages/desktopBingoGame'));
export const LazyDesktopBingoGamePage = props => <LazyLoad component={DesktopBingoGamePage} {...props} />;

const MobileBingoGamePage = LazyLoaderFactory(() => import(/* webpackChunkName: "MobileBingoGamePage" */ 'Pages/GamePages/mobileBingoGame'));
export const LazyMobileBingoGamePage = props => <LazyLoad component={MobileBingoGamePage} {...props} />;

// const OfferPage = LazyLoaderFactory(() => import(/* webpackChunkName: "OfferPage" */ 'Pages/LandingPage'));
// export const LazyOfferPage = props => <LazyLoad component={OfferPage} {...props} />;

const Lobby = LazyLoaderFactory(() => import(/* webpackChunkName: "Lobby" */ 'Pages/lobby'));
export const LazyLobby = props => <LazyLoad component={Lobby} {...props} />;

const Status404 = LazyLoaderFactory(() => import(/* webpackChunkName: "404" */ 'Pages/404'));
export const Lazy404 = props => <LazyLoad component={Status404} {...props} />;

const Status500 = LazyLoaderFactory(() => import(/* webpackChunkName: "500" */ 'Pages/500'));
export const Lazy500 = props => <LazyLoad component={Status500} {...props} />;

const Slots = LazyLoaderFactory(() => import(/* webpackChunkName: "SlotsPage" */ 'Pages/slots'));
export const LazySlots = props => <LazyLoad component={Slots} {...props} />;

const Bingo = LazyLoaderFactory(() => import(/* webpackChunkName: "BingoPage" */ 'Pages/bingo'));
export const LazyBingo = props => <LazyLoad component={Bingo} {...props} />;

const Promos = LazyLoaderFactory(() => import(/* webpackChunkName: "PromosPage" */ 'Pages/promos'));
export const LazyPromos = props => <LazyLoad component={Promos} {...props} />;

const SpecialEventPromo = LazyLoaderFactory(() => import(/* webpackChunkName: "SpecialEventPromo" */ 'Pages/SpecialEventPromo'));
export const LazySpecialEventPromo = props => <LazyLoad component={SpecialEventPromo} {...props} />;

const Rewards = LazyLoaderFactory(() => import(/* webpackChunkName: "RewardsPage" */ 'Pages/rewards'));
export const LazyRewards = props => <LazyLoad component={Rewards} {...props} />;

const PayoutRates = LazyLoaderFactory(() => import(/* webpackChunkName: "PayoutRates" */ 'Pages/GenericInfoPages/payoutRates'));
export const LazyPayoutRates = props => <LazyLoad component={PayoutRates} {...props} />;

const DragonfishOperatedSites = LazyLoaderFactory(() => import(/* webpackChunkName: "DragonfishOperatedSites" */ 'Pages/GenericInfoPages/dragonfishOperatedSites'));
export const LazyDragonfishOperatedSites = props => <LazyLoad component={DragonfishOperatedSites} {...props} />;

const TermsAndConditions = LazyLoaderFactory(() => import(/* webpackChunkName: "TermsAndConditions" */ 'Pages/GenericInfoPages/termsAndConditions'));
export const LazyTermsAndConditions = props => <LazyLoad component={TermsAndConditions} {...props} />;

const TermsAndConditionsChangelog = LazyLoaderFactory(() => import(/* webpackChunkName: "TermsAndConditions" */ 'Pages/GenericInfoPages/termsAndConditionsChangelog'));
export const LazyTermsAndConditionsChangelog = props => <LazyLoad component={TermsAndConditionsChangelog} {...props} />;

const PromotionalTerms = LazyLoaderFactory(() => import(/* webpackChunkName: "TermsAndConditions" */ 'Pages/GenericInfoPages/promotionalTerms'));
export const LazyPromotionalTerms = props => <LazyLoad component={PromotionalTerms} {...props} />;

const AccountVerification = LazyLoaderFactory(() => import(/* webpackChunkName: "AccountVerification" */ 'Pages/GenericInfoPages/accountVerification'));
export const LazyAccountVerification = props => <LazyLoad component={AccountVerification} {...props} />;

const AgeAndIdentityCheck = LazyLoaderFactory(() => import(/* webpackChunkName: "AccountVerification" */ 'Pages/GenericInfoPages/ageAndIdentityCheck'));
export const LazyAgeAndIdentityCheck = props => <LazyLoad component={AgeAndIdentityCheck} {...props} />;

const IdentityCheckInfoPage = LazyLoaderFactory(() => import(/* webpackChunkName: "AccountVerification" */ 'Pages/GenericInfoPages/identityCheckInfoPage'));
export const LazyIdentityCheckInfoPage = props => <LazyLoad component={IdentityCheckInfoPage} {...props} />;

const SourceOfFunds = LazyLoaderFactory(() => import(/* webpackChunkName: "SourceOfFunds" */ 'Pages/GenericInfoPages/sourceOfFunds'));
export const LazySourceOfFunds = props => <LazyLoad component={SourceOfFunds} {...props} />;

// const BingoRules = LazyLoaderFactory(() => import(/* webpackChunkName: "BingoRules" */ 'Pages/GenericInfoPages/bingoRules'));
// export const LazyBingoRules = props => <LazyLoad component={BingoRules} {...props} />;

const NewPlayerHomepage = LazyLoaderFactory(() => import(/* webpackChunkName: "NewPlayerHomepage" */ 'Pages/NewPlayerHomepage'));
export const LazyNewPlayerHomepage = props => <LazyLoad component={NewPlayerHomepage} {...props} />;

const ResponsibleGamblingApp = LazyLoaderFactory(() => import(/* webpackChunkName: "ResponsibleGamblingApp" */ 'Pages/rgapp'));
export const LazyResponsibleGamblingApp = props => <LazyLoad component={ResponsibleGamblingApp} {...props} />;

const ResponsibleGamblingPage = LazyLoaderFactory(() => import(/* webpackChunkName: "ResponsibleGamblingPage" */ 'Pages/ResponsibleGamblingPage'));
export const LazyResponsibleGamblingPage = props => <LazyLoad component={ResponsibleGamblingPage} {...props} />;

const SaferGamblingWeekPage = LazyLoaderFactory(() => import(/* webpackChunkName: "SaferGamblingWeekPage" */ 'Pages/SaferGamblingWeekPage'));
export const LazySaferGamblingWeekPage = props => <LazyLoad component={SaferGamblingWeekPage} {...props} />;

const SupportPage = LazyLoaderFactory(() => import(/* webpackChunkName: "SupportPage" */ 'Pages/GenericInfoPages/supportPage'));
export const LazySupportPage = props => <LazyLoad component={SupportPage} {...props} />;

const ChangePasswordPage = LazyLoaderFactory(() => import(/* webpackChunkName: "ChangePasswordPage" */ 'Pages/ChangePassword'));
export const LazyChangePasswordPage = props => <LazyLoad component={ChangePasswordPage} {...props} />;

const RequestPasswordPage = LazyLoaderFactory(() => import(/* webpackChunkName: "RequestPasswordPage" */ 'Pages/RequestPassword'));
export const LazyRequestPasswordPage = props => <LazyLoad component={RequestPasswordPage} {...props} />;

const AccountPendingAIDPage = LazyLoaderFactory(() => import(/* webpackChunkName: "AccountPendingAIDPage" */ 'Pages/Updates/accountPendingAID'));
export const LazyAccountPendingAIDPage = props => <LazyLoad component={AccountPendingAIDPage} {...props} />;

const ContactCSPage = LazyLoaderFactory(() => import(/* webpackChunkName: "ContactCSPage" */ 'Pages/GenericInfoPages/contactCs'));
export const LazyContactCSPage = props => <LazyLoad component={ContactCSPage} {...props} />;

const MaintenancePage = LazyLoaderFactory(() => import(/* webpackChunkName: "MaintenancePage" */ 'Pages/holdingPage'));
export const LazyMaintenancePage = props => <LazyLoad component={MaintenancePage} {...props} />;

const VerificationMessageReceiver = LazyLoaderFactory(() => import(/* webpackChunkName: "VerificationMessagesLoginModal" */ 'Components/VerificationMessageReceiver'));
export const LazyVerificationMessageReceiver = props => <LazyLoad component={VerificationMessageReceiver} {...props} />;

const DepositPage = LazyLoaderFactory(() => import(/* webpackChunkName: "DepositPage" */ 'Pages/deposit'));
export const LazyDepositPage = props => <LazyLoad component={DepositPage} {...props} />;

const ServerProvidedContentPage = LazyLoaderFactory(() => import(/* webpackChunkName: "ServerProvidedContentPage" */ 'Pages/ServerProvidedContentPage'));
export const LazyServerProvidedContentPage = props => <LazyLoad component={ServerProvidedContentPage} {...props} />;

const RegistrationLayout = LazyLoaderFactory(() => import(/* webpackChunkName: "RegistrationLayout" */ 'Components/Layouts/Registration'));
export const LazyRegistrationLayout = props => <LazyLoad component={RegistrationLayout} {...props} />;

const PrimaryLayout = LazyLoaderFactory(() => import(/* webpackChunkName: "PrimaryLayout" */ 'Components/Layouts/Primary'));
export const LazyPrimaryLayout = props => <LazyLoad component={PrimaryLayout} {...props} />;
