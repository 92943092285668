import {useEffect, useRef, useState} from "react";
import {BRAND_THEME, IS_STAGING} from "Setup/config";
import {useDispatch, useSelector} from "react-redux";
import {THEMES} from "Setup/themes";
import useStorage from "Hooks/useStorage";
import {activeTheme} from "Redux/reducers/uiReducer";
import {updateTheme} from "Redux/actions/ui";
import componentLoader from "Helpers/chunkLoader";

export const useGomTheme = () => {
    const dispatch = useDispatch();
    const firstUpdate = useRef(true);
    const [themeReady, setThemeReady] = useState(false);
    const [theme, setTheme] = useState(BRAND_THEME);
    const {currentTheme} = useSelector(state => ({
        currentTheme: state.ui.currentTheme
    }));

    const {setValue, storedValue: storedTheme} = useStorage("activeTheme", activeTheme, "localStorage");
    const setNewTheme = theme => {
        setValue(theme);
        dispatch(updateTheme(theme));
    }

    useEffect(() => {
        if (IS_STAGING) {
            if (firstUpdate.current) {
                firstUpdate.current = false;
                loadTheme(storedTheme);
            } else {
                loadTheme(currentTheme);
            }
        } else {
            loadTheme(currentTheme);
        }
    }, [storedTheme, currentTheme]);

    const loadTheme = async (theme) => {
        const t = THEMES[theme];
        // const res = await t();
        const res = await componentLoader(t, 3);
        console.log(res);
        setTheme(res);
        setThemeReady(true);
    };

    const appliedTheme = themeReady ? theme : false;

    return {appliedTheme, themeReady, setNewTheme, themeCode: storedTheme};
};
