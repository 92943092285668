import {useDispatch, useSelector} from "react-redux";
import {IS_GOOGLE_BOT, IS_LOGGED_IN, ZENDESK_API_VERSION, ZENDESK_SCRIPT_URL} from "Setup/config";
import loadExternalScript from "Helpers/loadExternalScript";
import {updateLiveAgentStatus} from "Redux/actions/liveAgent";
import {useRedPill} from "Hooks/api/useRedPill";

export const useZendesk = () => {
    const liveChatAvailable = useSelector(state => state.liveAgent.isLoaded);
    const dispatch = useDispatch();

    const getZendeskTokenPath = ZENDESK_API_VERSION === "v2" ? "authorizedPaths.zendeskTokenV2" : "authorizedPaths.zendeskToken";
    const {execute, status, value, error} = useRedPill(getZendeskTokenPath);

    const getZendeskJWT = async () => {
        const res = await execute();

        try {
            if (res.code === 200) {
                return res.data.jwt;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    const initZendesk = () => {
        if (ZENDESK_API_VERSION === "v2") {
            initZendeskV2();
        } else {
            initZendeskV1();
        }
    };

    const initZendeskV1 = () => {
        if (IS_GOOGLE_BOT) return false;

        let userDetails = {name: "", email: ""};

        if (IS_LOGGED_IN) {
            const email = window?.MORPHEUS?.personal?.email;
            const username = window?.MORPHEUS?.core?.username;
            userDetails.email = email;
            userDetails.name = username;
        } else {
            userDetails = false;
        }

        window.zESettings = {
            "webWidget": {
                "analytics": true
            }
        };

        // Whenever JWT is ready to set authentication in the chat widget
        if (IS_LOGGED_IN) {
            window.zESettings.webWidget.authenticate = {
                chat: {
                    jwtFn: callback => {
                        getZendeskJWT().then(response => callback(response)).catch(e => {
                            //sendExceptionToSentry(e);
                            console.log(e)
                        });
                    }
                }
            };
        }

        const callbackOnSuccess = () => {
            const zE = window.zE;

            zE(function () {
                if (typeof zE === 'undefined') {
                    return false;
                }

                try {
                    if (userDetails) {
                        zE('webWidget', 'identify', userDetails);
                    } else {
                        zE('webWidget', 'logout');
                    }
                } catch (e) {
                    //sendExceptionToSentry(e);
                    // sendLogglyEvent('Zendesk', 'Error');
                }

                dispatch(updateLiveAgentStatus(true));
            });
        }

        loadExternalScript(ZENDESK_SCRIPT_URL, {callbackOnSuccess, id: 'ze-snippet'});
    };

    const initZendeskV2 = () => {
        if (IS_GOOGLE_BOT) return false;

        window.zESettings = {
            "webWidget": {
                "analytics": true
            }
        };

        const callbackOnSuccess = () => {
            const zE = window.zE;

            try {
                if (IS_LOGGED_IN) {
                    zE("messenger", "loginUser", function (callback) {
                        getZendeskJWT().then(response => callback(response)).catch(e => {
                            console.log(e)
                        });
                    })
                } else {
                    zE("messenger", "logoutUser");
                }
            } catch (e) {
                //sendExceptionToSentry(e);
                // sendLogglyEvent('Zendesk', 'Error');
            }

            dispatch(updateLiveAgentStatus(true));
        }

        loadExternalScript(ZENDESK_SCRIPT_URL, {callbackOnSuccess, id: 'ze-snippet'});
    };

    const launchLiveChat = () => {
        try {
            if (window.hasOwnProperty('zE')) {
                window.zE.activate();
            } else {
                console.log('Chat error');
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log('Live agent loading...');
        }
    };

    return {
        initZendesk,
        launchLiveChat,
        liveChatAvailable,
    };
};
