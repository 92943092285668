import {UPDATE_LOYALTY_POINTS_CONFIG, UPDATE_PLAYER_LOYALTY_POINTS} from "Redux/actions/loyalty";

const initialState = {
    config: [],
    configLoaded: false,
    loyaltyLoaded: false,
    loyaltyPoints: 0
};

const loyaltyReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PLAYER_LOYALTY_POINTS:
            return {...state, loyaltyLoaded: true, ...action.data};
        case UPDATE_LOYALTY_POINTS_CONFIG:
            return {...state, configLoaded: true, config: action.config};
        default:
            return {...state};
    }
};

export default loyaltyReducer;
