import {Box, Grid, Typography} from "@material-ui/core";

const GeoBlockedPage = () => {
    React.useEffect(() => {
        if (!document.getElementById('app').classList.contains('loaded')) {
            document.getElementById('app').classList.add('loaded');
        }
    }, []);

    return (
        <Grid container alignItems="center" direction="column" style={{textAlign: 'center', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
            <Grid item xs={10}>
                <Box>
                    <Typography variant="h4" component="h1" paragraph>Restricted Country</Typography>
                    <Typography variant="body2" style={{maxWidth: '600px'}}>You are not allowed to access or create an account from your current
                        location.</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default GeoBlockedPage;
