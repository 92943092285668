import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {fetchLobbyFail, updateLobby} from "Redux/actions/lobby";
import useStorage from "Hooks/useStorage";
import {useRedPill} from "Hooks/api/useRedPill";
import {useRecentGames} from "Hooks/api/useRecentGames";
import {IS_LOGGED_IN, IS_PROD} from "Setup/config";
import sendExceptionToSentry from "Helpers/handledSentryError";
import extendedDayjs from "Helpers/dayjs";

const useLobby = () => {
    const dispatch = useDispatch();
    const {_get} = useRecentGames();
    const {getRecentGames} = _get();
    const userState = IS_LOGGED_IN ? "LI" : "LO";
    const getLobbyPath = "smartLobby";
    const {execute, status} = useRedPill(getLobbyPath);
    const {setValue, storedValue: storedSmartLobby} = useStorage(`smartLobby${userState}`, null, "localStorage");

    const {lobbyConfig, gamesData, isLoaded, fetchLobbyFailed} = useSelector(state => {
        const fetchLobbyFailed = state.lobby.fetchLobbyFailed ?? false;
        const isLoaded = state.lobby.isLoaded && !fetchLobbyFailed;

        return {
            lobbyConfig: isLoaded ? state.lobby.navigation : [],
            gamesData: isLoaded ? Object.values(state.lobby.games) : [],
            fetchLobbyFailed,
            isLoaded
        }
    }, shallowEqual);

    const fetchLobby = async (ignoreLobbyAge = true) => {
        const LOBBY_REFRESH_MIN_AGE_IN_MINUTES = IS_PROD ? 15 : 1;
        const LOBBY_REFRESH_MIN_AGE_IN_SECONDS = 60 * LOBBY_REFRESH_MIN_AGE_IN_MINUTES;

        try {
            if (!ignoreLobbyAge && storedSmartLobby && storedSmartLobby?.lobbySaveTimestamp) {
                const now = extendedDayjs().unix();
                const ageDifference = now - storedSmartLobby.lobbySaveTimestamp;

                console.log(ageDifference);
                if (ageDifference < LOBBY_REFRESH_MIN_AGE_IN_SECONDS) {
                    const ageInFullMins = Math.floor(ageDifference / 60);
                    const ageInRemainingSeconds = (ageDifference - (ageInFullMins * 60));

                    console.log(`Lobby update ignored, only ${ageInFullMins} min, ${ageInRemainingSeconds} sec old.`);
                    return;
                } else {
                    console.log(`Lobby is over ${LOBBY_REFRESH_MIN_AGE_IN_MINUTES} mins old, refreshing.`);
                }
            }
        } catch (e) {
            console.log("Couldn't calculate lobby age!");
        }

        const saveLobby = (data) => {
            data.lobbySaveTimestamp = extendedDayjs().unix();

            //No saved lobby or out of date, save it and update store.
            setValue(data);
            dispatch(updateLobby(data));
        };

        const res = await execute();

        if (IS_LOGGED_IN) {
            getRecentGames();
        }

        try {
            if (res && res.code === 200 && res.data && res.data.length > 0) {
                saveLobby(res.data[0]);
            } else {
                if (!storedSmartLobby) {
                    dispatch(fetchLobbyFail());
                }
            }
        } catch (e) {
            dispatch(fetchLobbyFail());

            sendExceptionToSentry(e);
            console.log("Failed to load lobby");
        }
    };

    return {
        fetchLobby,
        lobbyConfig,
        gamesData,
        isLoaded,
        fetchLobbyFailed
    };
};

export default useLobby;
