// Action Types
export const UPDATE_PROMOTIONS_LIST = "UPDATE_PROMOTIONS_LIST";
export const UPDATE_USERS_PROMOTIONS_LIST = "UPDATE_USERS_PROMOTIONS_LIST";
export const UPDATE_USERS_ACTIVE_NOTIFICATIONS = "UPDATE_USERS_ACTIVE_NOTIFICATIONS";

// Action Creator
export const updatePromotionsList = (newPromotionsList) => ({
    type: UPDATE_PROMOTIONS_LIST,
    newPromotionsList,
});

export const updateUsersPromotionsList = (newUsersPromotionsList) => ({
    type: UPDATE_USERS_PROMOTIONS_LIST,
    newUsersPromotionsList,
});

export const updateUsersActiveNotifications = (newUsersActiveNotifications) => ({
    type: UPDATE_USERS_ACTIVE_NOTIFICATIONS,
    newUsersActiveNotifications,
});
