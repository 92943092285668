import {useRedPill} from "Hooks/api/useRedPill";
import {sendLogglyEvent} from "Helpers/loggly";

const useSendDepositTrackingEvent = () => {
    const {execute} = useRedPill('authorizedPaths.banking.action.deposit');

    const sendDepositTrackingEvent = async (success = false, depositAmount = null) => {
        try {
            if (depositAmount) {
                const value = Number(depositAmount);
                if (!isNaN(value)) {
                    const res = await execute({
                        method: 'POST', data: {
                            "result": success ? "success" : "fail",
                            "amount": value
                        }
                    });
                }
            }
        } catch (e) {
            sendLogglyEvent('datadog', 'deposit_tracking_error', {error: e})
        }
    };

    return {sendDepositTrackingEvent};
};


const useSendWithdrawalTrackingEvent = () => {
    const {execute} = useRedPill('authorizedPaths.banking.action.withdrawal');

    const sendWithdrawalTrackingEvent = async (success = false, withdrawalAmount = null) => {
        try {
            if (withdrawalAmount) {
                const value = Number(withdrawalAmount);
                if (!isNaN(value)) {
                    const res = await execute({
                        method: 'POST', data: {
                            "result": success ? "success" : "fail",
                            "amount": value
                        }
                    });
                }
            }
        } catch (e) {
            sendLogglyEvent('datadog', 'withdrawal_tracking_error', {error: e})
        }
    };

    return {sendWithdrawalTrackingEvent};
};

const useBankingTracking = () => {
    return {
        ...useSendDepositTrackingEvent(),
        ...useSendWithdrawalTrackingEvent()
    };
};

export default useBankingTracking;
