import {FETCH_LOBBY, FETCH_LOBBY_FAIL, UPDATE_LOBBY, UPDATE_RECENT_GAMES} from "Redux/actions/lobby";
import {getValueFromStorage} from "Helpers/storage";
import {IS_LOGGED_IN} from "Setup/config";

let initialState = {isLoaded: false, recentGames: [], isRecentGamesLoaded: false, fetchLobbyFailed: false};

const userState = IS_LOGGED_IN ? "LI" : "LO";
let savedLobby = getValueFromStorage(`smartLobby${userState}`, 'localStorage');
if (savedLobby) {
    initialState = {...initialState, isLoaded: true, ...savedLobby};

    let savedRecentGames = getValueFromStorage('recentGames', 'localStorage');
    if (savedRecentGames) {
        initialState = {...initialState, isLoaded: true, isRecentGamesLoaded: true, recentGames: savedRecentGames};
    }
}

const lobbyReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LOBBY:
            return {
                ...state,
                ...action.newLobby,
                fetchLobbyFailed: false,
                isLoaded: true,
            };
        case UPDATE_RECENT_GAMES:
            return {
                ...state,
                recentGames: action.newRecentGames,
                isRecentGamesLoaded: true
            };
        case FETCH_LOBBY:
            return {
                ...state,
                fetchLobbyFailed: false,
                isLoaded: false,
            };
        case FETCH_LOBBY_FAIL:
            return {
                ...state,
                fetchLobbyFailed: true,
                isLoaded: true,
            };
        default:
            return {
                ...state
            };
    }
};

export default lobbyReducer;
