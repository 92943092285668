import * as dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat');
const advancedFormat = require('dayjs/plugin/advancedFormat')
const utc = require('dayjs/plugin/utc')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isBetween = require('dayjs/plugin/isBetween')

const extendedDayjs = dayjs.extend(customParseFormat).extend(advancedFormat).extend(utc).extend(isSameOrAfter).extend(isBetween);

export default extendedDayjs;
