// Action Types
export const UPDATE_BINGO_FEED = "UPDATE_BINGO_FEED";
export const UPDATE_DF_BINGO_FEED = "UPDATE_DF_BINGO_FEED";

// Action Creator
export const updateBingoFeed = (newRooms, newSpecials, roomDictionary) => ({
    type: UPDATE_BINGO_FEED,
    newRooms,
    newSpecials,
    roomDictionary
});

export const updateDFBingoFeed = (dfRooms) => ({
    type: UPDATE_DF_BINGO_FEED,
    dfRooms
});
