import {UPDATE_AVAILABLE_CLAIMS, UPDATE_AVAILABLE_CLAIMS_STATUS, UPDATE_PLAYER_CLAIMS, UPDATE_PLAYER_CLAIMS_STATUS} from "Redux/actions/claims";

const claimsLoaded = !!window?.MORPHEUS?.account?.claims;
const initialPlayerClaims = claimsLoaded ? window.MORPHEUS.account.claims : [];

const initialState = {
    availableClaims: {
        claims: [],
        error: null,
        status: "idle",
        isLoaded: false
    },
    playerClaims: {
        claims: initialPlayerClaims,
        error: null,
        status: "idle",
        isLoaded: claimsLoaded
    }
};

const claimsReducer = (state = initialState, action) => {
    let playerClaims

    switch (action.type) {
        case UPDATE_PLAYER_CLAIMS:
            playerClaims = {...state.playerClaims, isLoaded: true, claims: Object.values(action.newPlayerClaims)};

            return {...state, playerClaims};
        case UPDATE_AVAILABLE_CLAIMS:
            const availableClaims = {...state.availableClaims, isLoaded: true, claims: action.newAvailableClaims};

            return {...state, availableClaims};
        case UPDATE_PLAYER_CLAIMS_STATUS:
            return {...state, playerClaims: {...state.playerClaims, status: action.status, error: action.error}};
        case UPDATE_AVAILABLE_CLAIMS_STATUS:
            return {...state, availableClaims: {...state.availableClaims, status: action.status, error: action.error}};
        default:
            return {...state};
    }
};

export default claimsReducer;
