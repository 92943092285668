import {useDispatch, useSelector} from "react-redux";
import {updateFreeSpinsGames} from "Redux/actions/freeSpins";
import {useRedPill} from "./api/useRedPill";
import {IS_FREE_SPINS_ENABLED, IS_MOBILE, IS_STAGING, MGS_FREE_SPINS_CONFIG, PLAYER_ID} from "Setup/config";
import {sendLogglyEvent} from "Helpers/loggly";

const findCgidByBundledGames = (bundledGames) => {
    const cidIndex = IS_MOBILE ? 1 : 0;
    let searchForGame = MGS_FREE_SPINS_CONFIG.find(g => g.mid === bundledGames.moduleId && g.cid[cidIndex] === bundledGames.clientId);

    if (searchForGame && searchForGame?.gcid) {
        console.log(searchForGame.gcid);
        return searchForGame.gcid;
    }
};

const useFreeSpins = () => {
    const dispatch = useDispatch();
    const {execute, status, value, error} = useRedPill("authorizedPaths.promotions.mgs-free-spins");

    const getUserFreeSpinsOffers = async () => {
        if (!IS_FREE_SPINS_ENABLED || IS_STAGING) return;

        let fsData = [];
        const res = await execute({url: PLAYER_ID});

        try {
            const {code, data, redirect} = res;
            if (redirect) {
                window.location = redirect;
                return;
            }

            if (code === 200) {
                const freeSpinsData = data.freeSpins;
                for (let fs in freeSpinsData) {
                    let gameIds = [];
                    for (let g in freeSpinsData[fs].bundledGames) {
                        const bg = freeSpinsData[fs].bundledGames[g];
                        const gameId = findCgidByBundledGames(bg);

                        if (gameId && !gameIds.includes(gameId)) gameIds.push(gameId);
                    }

                    if (gameIds.length > 0) {
                        fsData.push({
                            gameIds,
                            offerInstances: freeSpinsData[fs].offerInstances
                        })
                    }
                }

                console.log(fsData);

                dispatch(updateFreeSpinsGames(fsData));
            }

            return true;
        } catch (e) {
            sendLogglyEvent('Free Spins', 'Error parsing free spins data', {error: e})
            console.log(e);
        }
    };

    const {freeSpinsGamesLoaded, freeSpinsGames} = useSelector(state => ({
        freeSpinsGamesLoaded: state.freeSpins.isLoaded, freeSpinsGames: state.freeSpins.games,
    }));

    return {
        _getFreeSpins: getUserFreeSpinsOffers, isFreeSpinsLoaded: freeSpinsGamesLoaded, freeSpinsData: freeSpinsGames,
    };
}

export default useFreeSpins;
