// Action Types
export const UPDATE_MESSAGES_COUNT = "UPDATE_MESSAGES_COUNT";
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const UPDATE_MESSAGES_LIST = "UPDATE_MESSAGES_LIST";

// Action Creator
export const updateMessagesCount = (messageCount, unreadMessagesCount) => ({
    type: UPDATE_MESSAGES_COUNT,
    messageCount,
    unreadMessagesCount
});

export const updateMessagesList = (newMessagesList) => ({
    type: UPDATE_MESSAGES_LIST,
    newMessagesList,
});

export const updateMessage = (newMessage) => ({
    type: UPDATE_MESSAGE,
    newMessage,
});
