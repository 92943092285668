import {useHistory} from "react-router-dom";
import {BINGO_GAME_PAGE, CASINO_GAME_PAGE, LOGIN_URL} from "Setup/urls";
import useLobby from "Hooks/useLobby";
import {IS_LOGGED_IN} from "Setup/config";

const useGames = () => {
    const history = useHistory();
    const {gamesData} = useLobby();

    const findGameByCatalogueGameId = catalogueGameId => {
        const filteredGame = gamesData.find(game => game['catalogueGameId'] === catalogueGameId);
        return filteredGame ? filteredGame : false;
    }

    const launchCasinoGame = (gameId, newWindow = false) => {
        if (!IS_LOGGED_IN) {
            history.push(LOGIN_URL);
        } else {
            if (gameId) {
                if (newWindow) {
                    window.open(`/game/${CASINO_GAME_PAGE}/${gameId}`);
                } else {
                    history.push(`/game/${CASINO_GAME_PAGE}/${gameId}`);
                }
            }
        }
    };

    const launchBingoGame = gameId => {
        if (!IS_LOGGED_IN) {
            history.push(LOGIN_URL);
        } else {
            if (gameId) {
                history.push(`/game/${BINGO_GAME_PAGE}/${gameId}`);
            }
        }
    };

    return {
        launchCasinoGame,
        launchBingoGame,
        findGameByCatalogueGameId,
    };
};

export default useGames;
