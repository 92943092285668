import {IS_LOGGED_IN, IS_MOBILE, NEW_PLAYER_HOMEPAGE_ENABLED} from "./config";

const HOME = '/';
const LOBBY = '/lobby'
export const NEW_PLAYER_HOMEPAGE_URL = `/welcome-offer`;

export const HOME_URL = IS_LOGGED_IN ? LOBBY : NEW_PLAYER_HOMEPAGE_ENABLED ? NEW_PLAYER_HOMEPAGE_URL : HOME;
export const REGISTER_URL = '/register';
export const LOGIN_URL = '/login';
export const BINGO_URL = '/bingo';
export const SLOTS_URL = '/slots';
export const PROMOS_URL = '/promos';
export const REWARDS_URL = '/rewards';
export const LOST_DETAILS_URL = '/lost-details';
export const RESPONSIBLE_GAMBLING_INFO_URL = `/responsible-gambling`;
export const RESPONSIBLE_GAMBLING_URL = `/responsible-gambling-app`;
export const SAFER_GAMBLING_URL = `/safer-gambling-week`;
export const BROADWAY_GAMING_GROUP_SITES_URL = "/broadway-gaming-group-sites";

export const ABOUT_US_URL = '/about-us';
export const PROMOTIONAL_TERMS_URL = '/promotional-terms';
export const BONUS_MONEY_RULES_URL = '/bonus-money-rules';
export const TERMS_AND_CONDITIONS_URL = '/terms-and-conditions';
export const COOKIE_POLICY_URL = '/cookie-policy';
export const PRIVACY_POLICY_URL = '/privacy-policy';
export const BINGO_RULES_URL = '/bingo-rules';
export const PAYOUT_RATES_URL = '/payout-rates';
export const PROTECTION_OF_FUNDS_URL = '/protection-of-funds';
export const FB_TERMS_URL = '/fb-competitions-terms-and-conditions';
export const SOURCE_OF_FUNDS_URL = '/source-of-funds';
export const PLAYER_PROTECTION_URL = '/player-protection';
export const SECURITY_URL = '/security';
export const SUPPORT_URL = '/support';

export const BINGO_GAME_PAGE = IS_MOBILE ? 'mobile-bingo-game' : 'desktop-bingo-game';
export const CASINO_GAME_PAGE = IS_MOBILE ? 'mobile-casino-game' : 'desktop-casino-game';
