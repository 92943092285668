import {UPDATE_BINGO_FEED, UPDATE_DF_BINGO_FEED} from "Redux/actions/bingo";
import {getValueFromStorage} from "Helpers/storage";

let initialState = {isLoaded: false, rooms: [], specials: [], dfRooms: {isLoaded: false, rooms: []}};

let savedBingoFeed = getValueFromStorage(`bingoFeed`, 'sessionStorage');
if (savedBingoFeed) {
    initialState.rooms = savedBingoFeed.normalRooms;
    initialState.dictionary = savedBingoFeed.roomDictionary;
    initialState.specials = savedBingoFeed.specialRooms;
    initialState = {...initialState, isLoaded: true};
}

const bingoReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_BINGO_FEED:
            return {
                ...state,
                isLoaded: true,
                rooms: action.newRooms,
                specials: action.newSpecials,
                dictionary: action.roomDictionary
            };
        case UPDATE_DF_BINGO_FEED:
            return {
                ...state,
                dfRooms: {
                    isLoaded: true,
                    rooms: action.dfRooms
                }
            };
        default:
            return {
                ...state
            };
    }
};

export default bingoReducer;
