import {UPDATE_PLAYER_WALLETS, UPDATE_PLAYER_WALLETS_STATUS} from "Redux/actions/wallets";

const walletsLoaded = !!window?.MORPHEUS?.account?.wallet;
const initialPlayerWallets = walletsLoaded ? window.MORPHEUS.account.wallet : [];

const initialWallets = {
    status: "idle",
    isLoaded: walletsLoaded,
    wallets: initialPlayerWallets
};


const walletsReducer = (state = initialWallets, action) => {
    switch (action.type) {
        case UPDATE_PLAYER_WALLETS:
            return {...state, isLoaded: true, wallets: action.newPlayerWallets};
        case UPDATE_PLAYER_WALLETS_STATUS:
            return {...state, status: action.status};
        default:
            return {...state};
    }
};

export default walletsReducer;
