import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress, Dialog, DialogTitle as MuiDialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {closeModal} from "Redux/actions/ui";
import CloseIcon from '@material-ui/icons/Close';
import {BP_MEDIUM} from "Theme/breakpoints";
import clsx from "clsx";

const useDialogTitleStyles = makeStyles(theme => {
    const ModalSettings = theme.overrides.Modal;

    return {
        root: {
            [theme.breakpoints.down(BP_MEDIUM)]: {
                padding: theme.spacing(1, 2),
            },
            background: ModalSettings.titleBackground,
            color: ModalSettings.titleColor,
            textAlign: "left",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: ModalSettings.titleColor,
            [theme.breakpoints.down(BP_MEDIUM)]: {
                padding: theme.spacing(0.5)
            },
        },
    }
});

const DialogTitle = (props) => {
    const classes = useDialogTitleStyles();
    const {children, showClose, onClose} = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root}>
            <Typography variant="h6">{children}</Typography>
            {(showClose && onClose) ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => {
    const ModalSettings = theme.overrides.Modal;

    return {
        title: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "92.5%"
        },
        paper: {
            width: "100%",
            borderColor: ModalSettings.borderColor
        },
        loadingOverlay: {
            position: "absolute",
            background: "#ffffff94",
            top: 64, // Title Height
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
            backdropFilter: "blur(2px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }
});

const ModalBase = ({
                       children,
                       modalId,
                       title,
                       maxWidth = 'sm',
                       closeOnClickOutside = true,
                       showClose = true,
                       isLoading = false,
                       handleClose = null,
                       paperStyles = false,
                       fullscreen = true,
                       ...rest
                   }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const theme = useTheme();
    const fullScreen = fullscreen && useMediaQuery(theme.breakpoints.down(BP_MEDIUM));

    const onClose = () => {
        if (handleClose) {
            handleClose();
        } else {
            dispatch(closeModal(modalId))
        }
    };

    return (
        <Dialog
            disableBackdropClick={!closeOnClickOutside}
            disableEscapeKeyDown={!closeOnClickOutside}
            TransitionComponent={Transition}
            maxWidth={maxWidth}
            PaperProps={{classes: {root: clsx(classes.paper, paperStyles)}}}
            fullScreen={fullScreen}
            onClose={onClose}
            aria-labelledby="dialog-title"
            open={true}
            {...rest}
        >
            {title &&
                <DialogTitle id="dialog-title" className={classes.title} onClose={onClose} showClose={!isLoading && showClose}>
                    {title}
                </DialogTitle>
            }

            {isLoading &&
                <div className={classes.loadingOverlay}>
                    <CircularProgress/>
                </div>
            }

            {children}
        </Dialog>
    );
}

export default ModalBase;
