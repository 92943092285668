import {getValueFromStorage} from "Helpers/storage";
import {
    CLEAR_LOGIN_ERROR,
    CLOSE_ALL_MODALS,
    CLOSE_MODAL,
    OPEN_MODAL,
    SET_LOGIN_ERROR,
    TOGGLE_DRAWER,
    TOGGLE_PANEL,
    UPDATE_FREE_SPINS_SHUFFLE_STATUS,
    UPDATE_LOBBY_BANNERS_LIST,
    UPDATE_REWARDS_LIST,
    UPDATE_SPECIAL_EVENT_PROMO_LIST,
    UPDATE_THEME
} from "Redux/actions/ui";
import {
    BRAND_THEME,
    IS_LOGGED_IN,
    IS_LOGIN_LIGHTBOX_ENABLED,
    IS_RETURNING_PLAYER_LIGHTBOX_ENABLED,
    IS_THEME_SWITCHER_ENABLED,
    JUST_LOGGED_IN,
    PROMOS_ENABLED,
    RETURNING_PLAYER_LIGHTBOX_CLAIM_NAME,
    RISK_CATEGORY,
    RISK_CATEGORY_TRIGGERS,
    RISK_RATING,
    RISK_RATING_TRIGGERS,
    RISK_STATUS_ON_MODAL_TRIGGERED_CLAIM_NAME,
    SHOW_WELCOME_LIGHTBOX,
    WELCOME_LIGHTBOX_PROMOCODE
} from "Setup/config";
import {UPDATE_PROMOTIONS_LIST} from "Redux/actions/promotions";
import extendedDayjs from "Helpers/dayjs";

export let activeTheme = BRAND_THEME;
if (IS_THEME_SWITCHER_ENABLED) {
    activeTheme = getValueFromStorage('activeTheme', 'localStorage');
    if (!activeTheme) activeTheme = BRAND_THEME;
}

let initialBannersState = {isLoaded: false, isUpdated: false, list: []};
let initialRewardsState = {isLoaded: false, isUpdated: false, list: []};
let initialSpecialEventPromosState = {isLoaded: false, mastergroup: false, list: []};

const userState = IS_LOGGED_IN ? "LI" : "LO";
let savedBanners = getValueFromStorage(`lobbyBanners${userState}`, 'localStorage');
if (savedBanners) {
    initialBannersState = {isLoaded: true, isUpdated: false, list: savedBanners};
}

// Create the modal queue and add any modals that need to be shown on boot.
let modalQueue = [];
const addLoginModalsToModalQueue = (modalQueue) => {
    let claimsAvailableOnLogin = window?.MORPHEUS?.account?.claims ?? [];
    let riskStatusOnModalTriggeredClaimId = false;
    let riskStatusOnModalTriggeredClaimValue = false;

    let returningPlayerLightboxClaimId = false;
    let returningPlayerLightboxClaimValue = false;

    if (Array.isArray(claimsAvailableOnLogin)) {
        claimsAvailableOnLogin.forEach(claimObj => {
            if (riskStatusOnModalTriggeredClaimId && returningPlayerLightboxClaimId) return;

            if (claimObj.type === RISK_STATUS_ON_MODAL_TRIGGERED_CLAIM_NAME) {
                riskStatusOnModalTriggeredClaimId = claimObj.id;
                riskStatusOnModalTriggeredClaimValue = Number(claimObj.value);
            }

            if (claimObj.type === RETURNING_PLAYER_LIGHTBOX_CLAIM_NAME) {
                returningPlayerLightboxClaimId = claimObj.id;
                returningPlayerLightboxClaimValue = claimObj.value;
            }
        });
    }

    // const showServiceAnnouncementLightbox = SERVICE_ANNOUNCEMENT_ENABLED && JUST_LOGGED_IN; // RP-1162 (Add ability to trigger modal once per session)
    const showRiskTriggersLightbox = RISK_CATEGORY_TRIGGERS.includes(RISK_CATEGORY) && RISK_RATING_TRIGGERS.includes(RISK_RATING) && RISK_RATING !== riskStatusOnModalTriggeredClaimValue;
    const showWelcomeNewPlayerLightbox = IS_LOGIN_LIGHTBOX_ENABLED && SHOW_WELCOME_LIGHTBOX && WELCOME_LIGHTBOX_PROMOCODE;
    const showReturningPlayerLightbox = IS_RETURNING_PLAYER_LIGHTBOX_ENABLED && (!returningPlayerLightboxClaimValue || returningPlayerLightboxClaimValue !== extendedDayjs().format("DD-MM-YYYY"));

    // if (showServiceAnnouncementLightbox) {
    //     modalQueue.push({modalId: "ServiceAnnouncement", modalProps: {message: SERVICE_ANNOUNCEMENT_MESSAGE}});
    // }

    if (showRiskTriggersLightbox) {
        modalQueue.push({modalId: "RiskTrigger", modalProps: {existingClaimId: riskStatusOnModalTriggeredClaimId}});
    }

    if (!showRiskTriggersLightbox && PROMOS_ENABLED) {
        if (showWelcomeNewPlayerLightbox) {
            modalQueue.push({modalId: "WelcomeLightbox", modalProps: {promocode: WELCOME_LIGHTBOX_PROMOCODE}});
        } else {
            if (showReturningPlayerLightbox) {
                modalQueue.push({modalId: "ReturningLoginLightbox", modalProps: {isPending: true, existingClaimId: returningPlayerLightboxClaimId}})
            }
        }
    }

    return modalQueue;
}

if (IS_LOGGED_IN && JUST_LOGGED_IN) {
    modalQueue = addLoginModalsToModalQueue(modalQueue);
}

const initialState = {
    currentTheme: activeTheme,
    drawers: {
        leftDrawer: {
            isOpen: false
        },
        rightDrawer: {
            isOpen: false
        },
        freeSpinsGameDrawer: {
            isOpen: false
        }
    },
    panels: {
        notifications: {
            isOpen: false
        }
    },
    modals: {
        queue: modalQueue
    },
    slideDownGames: {
        freeSpinsShuffle: {
            // slideDownActive: false,
            isLoaded: true,
            isLoading: false,
            hasError: false,
            playedAlready: false,
            prizeLoading: false,
            data: false
        }
    },
    lobbyBanners: initialBannersState,
    rewardsPromos: initialRewardsState,
    specialEventPromos: initialSpecialEventPromosState,
    loginError: false,
    loginErrorMessage: ""
};

const uiReducer = (state = initialState, action) => {
    let updatedQueue;
    switch (action.type) {
        case UPDATE_THEME:
            return {...state, currentTheme: action.newTheme};
        case TOGGLE_DRAWER:
            return {
                ...state,
                drawers: {
                    ...state.drawers,
                    [action.drawer]: {
                        isOpen: action.isOpen
                    }
                }
            };
        case TOGGLE_PANEL:
            return {
                ...state,
                panels: {
                    ...state.panels,
                    [action.panel]: {
                        isOpen: !state.panels[action.panel].isOpen
                    }
                }
            };
        case OPEN_MODAL:
            updatedQueue = action.clearExistingModals ? [] : [...state.modals.queue];
            updatedQueue.push({modalId: action.modalId, modalProps: action.modalProps});

            updatedQueue = updatedQueue.filter((m, index, self) =>
                    index === self.findIndex((t) => (
                        t.modalId === m.modalId
                    ))
            )

            return {
                ...state,
                modals: {
                    ...state.modals,
                    queue: updatedQueue
                }
            };
        case CLOSE_MODAL:
            updatedQueue = [...state.modals.queue];
            if (updatedQueue.some(item => item.modalId === action.modalId)) {
                updatedQueue = updatedQueue.filter(item => item.modalId !== action.modalId);
            }

            return {
                ...state,
                modals: {
                    ...state.modals,
                    queue: updatedQueue
                }
            };
        case CLOSE_ALL_MODALS:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    queue: []
                }
            };
        case UPDATE_PROMOTIONS_LIST:
            // When the promotions are updated, we need to check whether there's an open
            // promotion modal and close it if it's no longer available to the user.

            const promoIds = action.newPromotionsList.map(p => p.id);
            const currentOpenModals = state.modals.queue.filter(modal => modal.modalId === "Promotion");
            const currentOpenPromoModal = currentOpenModals.map(m => m.modalProps.promoId);

            if (currentOpenPromoModal.length) {
                if (!promoIds.includes(currentOpenPromoModal[0])) {
                    const updatedQueue = state.modals.queue.filter(modal => modal.modalId !== "Promotion");

                    return {
                        ...state,
                        modals: {
                            ...state.modals,
                            queue: updatedQueue
                        }
                    };
                }
            }

            return state;

        case UPDATE_FREE_SPINS_SHUFFLE_STATUS:
            return {
                ...state,
                slideDownGames: {
                    freeSpinsShuffle: {
                        ...state.slideDownGames.freeSpinsShuffle,
                        ...action.data
                    }
                }
            };
        case UPDATE_LOBBY_BANNERS_LIST:
            return {
                ...state,
                lobbyBanners: {
                    isLoaded: true,
                    isUpdated: true,
                    list: action.data
                }
            };
        case UPDATE_REWARDS_LIST:
            return {
                ...state,
                rewardsPromos: {
                    isLoaded: true,
                    isUpdated: true,
                    list: action.data
                }
            };
        case UPDATE_SPECIAL_EVENT_PROMO_LIST:
            return {
                ...state,
                specialEventPromos: {
                    isLoaded: true,
                    mastergroup: action.mastergroup,
                    list: action.data
                }
            };
        case SET_LOGIN_ERROR:
            return Object.assign({}, state, {
                loginError: true,
                loginErrorMessage: action.errorMessage
            });
        case CLEAR_LOGIN_ERROR:
            return Object.assign({}, state, {
                loginError: false,
                loginErrorMessage: ""
            });
        default:
            return {...state};
    }
};

export default uiReducer;
