import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

function configureStore(initialState) {
    return createStore(
        rootReducer,
        applyMiddleware(thunk)
    );
}

export default configureStore;
