// Action Types
export const UPDATE_PLAYER_LIMITS = "UPDATE_PLAYER_LIMITS";
export const UPDATE_PLAYER_LIMIT = "UPDATE_PLAYER_LIMIT";
export const UPDATE_SESSION_REMINDER = "UPDATE_SESSION_REMINDER";

// Action Creator

// Limits from my-account
export const updatePlayerLimits = (newPlayerLimits) => ({
    type: UPDATE_PLAYER_LIMITS,
    newPlayerLimits,
});

// Individual limit on get deposit limits
export const updatePlayerLimit = (interval, setting) => ({
    type: UPDATE_PLAYER_LIMIT,
    interval,
    setting
});

// Session reminder
export const updateSessionReminder = (interval) => ({
    type: UPDATE_SESSION_REMINDER,
    interval
});
