import {UPDATE_PROMOTIONS_LIST, UPDATE_USERS_ACTIVE_NOTIFICATIONS, UPDATE_USERS_PROMOTIONS_LIST} from "Redux/actions/promotions";

let initialState = {
    list: null,
    history: null,
    activePromotions: null,
    isLoaded: false,
    isHistoryLoaded: false,
    isActivePromotionsLoaded: false
};

const promotionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PROMOTIONS_LIST:
            return {...state, isLoaded: true, list: action.newPromotionsList};
        case UPDATE_USERS_PROMOTIONS_LIST:
            return {...state, isHistoryLoaded: true, history: action.newUsersPromotionsList};
        case UPDATE_USERS_ACTIVE_NOTIFICATIONS:
            return {...state, isActivePromotionsLoaded: true, activePromotions: action.newUsersActiveNotifications};
        default:
            return {...state};
    }
};

export default promotionsReducer;
