import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {IS_LOGGED_IN} from "Setup/config";
import {HOME_URL, LOGIN_URL} from "Setup/urls";

export const LoggedOutRoute = ({component, render, ...rest}) => {
    const Component = component || render;
    return !IS_LOGGED_IN ? <Route path={rest.path} render={({match}) => <Component match={match}/>}/> : <Redirect to={{pathname: HOME_URL}}/>;
};

export const LoggedInRoute = ({component, render, ...rest}) => {
    const Component = component || render;
    return IS_LOGGED_IN ? <Route path={rest.path} render={({match}) => <Component match={match}/>}/> : <Redirect to={{pathname: LOGIN_URL}}/>;
};
