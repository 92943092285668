import {GA4_ENABLED, GA4_ID, IS_STAGING} from "Setup/config";
import {gtag} from 'ga-gtag';

export const sendLaunchFirstDepositEvent = (bonusType, promoCode) => {
    sendGA4Event(`banking_launch`, {
        bonus_type: bonusType,
        promo_code: promoCode,
    });
};

export const sendGAEvents = ({category = "", action = "", label = ""}) => {
    if (!category.length || !action.length) return;

    let ga4Obj = {action};
    if (label.length) ga4Obj.label = label;

    sendGA4Event(category, ga4Obj);
};

export const sendGA4Event = (name, parameters) => {
    if (IS_STAGING) {
        console.log(name, JSON.stringify(parameters));
    }

    if (GA4_ENABLED) {
        const DEFAULT_SEND_TO = [GA4_ID];

        gtag('event', name, {send_to: DEFAULT_SEND_TO, ...parameters});
    }
};
