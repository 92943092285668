export const PREFIXES = {
    DAILY_DEAL: "DD_",
    FREE_SPINS_GAME: "FS_",
    PICK_YOUR_BONUS: "PYB_",
    SPIN_THE_WHEEL: "WHEEL_"
};

const MASTERGROUPS = {
    DAILY_DEAL: `${PREFIXES.DAILY_DEAL}MASTERGROUP`,
    FREE_SPINS_GAME: `${PREFIXES.FREE_SPINS_GAME}MASTERGROUP`,
    PICK_YOUR_BONUS: `${PREFIXES.PICK_YOUR_BONUS}MASTERGROUP`,
    SPIN_THE_WHEEL: `${PREFIXES.SPIN_THE_WHEEL}MASTERGROUP_ACCESS_GRANTED`
};

export const EXCLUDER_PROMOCODES = {
    DAILY_DEAL: `${PREFIXES.DAILY_DEAL}EXCLUDER`,
    FREE_SPINS_GAME: `${PREFIXES.FREE_SPINS_GAME}EXCLUDER`,
    SPIN_WHE_WHEEL: "WHEELCLAIMED_V2",
};

export const PROMO_PREFIXES = {
    NOTIFICATION: "NOTIFICATION_",

    LOBBY_BANNER: "LOBBY_BANNER",
    LOBBY_BANNER_ADDITIONAL: "LOBBY_BANNER_ADDITIONAL",

    REWARDS: "REWARDS_LEVEL_",
    REWARDS_INFO: "REWARDS_INFO_",

    DD_MASTERGROUP: MASTERGROUPS.DAILY_DEAL,
    DD_PRIZE: `${PREFIXES.DAILY_DEAL}PRIZE`,

    FREE_SPINS_GAME_MASTERGROUP: MASTERGROUPS.FREE_SPINS_GAME,
    FREE_SPINS_GAME_PREFIX: `${PREFIXES.FREE_SPINS_GAME}PRIZE_`,

    PYB_MASTERGROUP: MASTERGROUPS.PICK_YOUR_BONUS,
    PYB_OPTION: `${PREFIXES.PICK_YOUR_BONUS}OPTION_`,
    PYB_OPTION_ACTUAL: "_ACTUALOFFER",

    SPIN_THE_WHEEL_MASTERGROUP: MASTERGROUPS.SPIN_THE_WHEEL,
    SPIN_THE_WHEEL_SPUN: `${PREFIXES.SPIN_THE_WHEEL}SPUN_V2`,
    SPIN_THE_WHEEL_PRIZE: `${PREFIXES.SPIN_THE_WHEEL}PRIZE_`,
    SPIN_THE_WHEEL_PRIZE_CLAIMED: `${PREFIXES.SPIN_THE_WHEEL}CLAIMED_V2`
};
