import useGames from "Hooks/useGames";
import useStorage from "Hooks/useStorage";
import {useRedPill} from "Hooks/api/useRedPill";
import {useMyAccount} from "Hooks/api/useAccount";
import {useDispatch, useSelector} from "react-redux";
import {updatePromotionsList, updateUsersActiveNotifications, updateUsersPromotionsList} from "Redux/actions/promotions";
import {
    CAN_REGISTER,
    IS_DEV,
    IS_LOGGED_IN,
    NEW_SHARED_CDN_PATH,
    OLD_SHARED_CDN_PATH,
    SHARED_CDN_BRAND_ID,
    SPECIAL_EVENT_PROMO_ENABLED,
    SPECIAL_EVENT_PROMO_MASTERGROUP,
    SPECIAL_EVENT_PROMO_PREFIX,
    USE_NEW_CDN
} from "Setup/config";
import {updateLobbyBannersList, updateRewardsList, updateSpecialEventPromoList} from "Redux/actions/ui";
import {PREFIXES, PROMO_PREFIXES} from "Constants";
import {sleep} from "Helpers/async";
import {sendGA4Event} from "Helpers/gaEvents";
import {sendLogglyEvent} from "Helpers/loggly";
import {useMemo} from "react";

function compare(a, b) {
    const prop = 'priority';

    if (a[prop] < b[prop]) {
        return -1;
    }
    if (a[prop] > b[prop]) {
        return 1;
    }

    return 0;
}

const filterByCustomField = (promo) => {
    let showPromoToUser = true;

    if (IS_LOGGED_IN) {
        try {
            const playerId = MORPHEUS.core.playerId;
            const playerIdIsEven = playerId % 2 === 0;
            const playerIdIsOdd = playerId % 2 === 1;

            promo.customFields.forEach(cf => {
                if (cf?.key === "excludeOddPlayerIds" && cf.value === "true" && playerIdIsOdd) {
                    showPromoToUser = false;
                }

                if (cf?.key === "excludeEvenPlayerIds" && cf.value === "true" && playerIdIsEven) {
                    showPromoToUser = false;
                }
            });
        } catch (e) {
            console.log(e);
            return true;
        }
    }

    return showPromoToUser;
}

function useGetPromotion() {
    const getPromotionPath = "authorizedPaths.promotions.get";
    const {execute, status, value, error} = useRedPill(getPromotionPath);

    const getPromotion = async (bonusId) => {
        const res = await execute({url: '/' + bonusId});

        try {
            if (res.code === 200) {
                return res.data;
            }
        } catch (e) {
            console.log(e);
        }

        return false;
    };

    return {getPromotionStatus: status, getPromotion};
}

function sendBonusResponseDetailsToGA(bonusResponse) {
    const CATEGORY = "Bonuses";

    try {
        const {itemsPerPage, totalItems, totalItemsToDisplay} = bonusResponse;

        if (IS_LOGGED_IN && itemsPerPage) {
            sendGA4Event(CATEGORY, {
                itemsPerPage,
                totalItems,
                totalItemsToDisplay,
            });
        }
    } catch (err) {
        sendLogglyEvent(CATEGORY, "Failed to deconstruct a successful bonus response", {error: err.message});
    }
}

function useListPromotions() {
    const userState = IS_LOGGED_IN ? "LI" : "LO";
    const {setValue} = useStorage(`lobbyBanners${userState}`, null, "localStorage");

    const {findGameByCatalogueGameId} = useGames();
    const dispatch = useDispatch();
    const listPromotionsPath = "content.promotions";
    const {execute, status, value, error} = useRedPill(listPromotionsPath);

    const organizeBanners = banners => {
        if (IS_LOGGED_IN) {
            banners = banners.filter(banner => !banner.isVisibleToLoggedOut);
        }

        if (!IS_LOGGED_IN && !CAN_REGISTER) {
            banners = banners.filter(banner => !banner.customFields.some(cf => cf.key === "SHOW_TO_RETURNING_PLAYERS" && cf.value === "false"));
        }

        banners = banners.filter(banner => {
            if (!filterByCustomField(banner)) return false;

            if (banner.description === "LAUNCH_GAME") {
                banner.detailedDescription = banner.detailedDescription.replace(/^\s+|\s+$/g, '');

                if (IS_LOGGED_IN) {
                    // Filter out banners with game launch if user doesn't have game in lobby
                    if (!findGameByCatalogueGameId(banner.detailedDescription)) {
                        return false;
                    }
                }
            }

            return true;
        });

        return banners.map(banner => ({
            id: banner.id,
            action: banner.description,
            param: banner.detailedDescription,
            imagePath: banner.images.default.url,
            altText: banner.images.default.alt
        }))
    };

    const dispatchPromotionsUpdate = ({
                                          newBanners,
                                          filteredPromotions,
                                          filteredRewards,
                                          specialEventPromoMastergroup = null,
                                          specialEventPromoBonuses = null
                                      }) => {
        dispatch(updateLobbyBannersList(newBanners));
        dispatch(updatePromotionsList(filteredPromotions));
        dispatch(updateRewardsList(filteredRewards));

        if (specialEventPromoMastergroup && specialEventPromoBonuses) {
            dispatch(updateSpecialEventPromoList(specialEventPromoMastergroup, specialEventPromoBonuses));
        }
    };

    const maxRetriesPerListPromotionsCall = 2;
    const listPromotions = async (attempt = 0) => {
        let currentAttempt = attempt;
        const res = await execute({url: `?take=180`});
        currentAttempt++;

        try {
            if (res.code === 200) {
                // sendBonusResponseDetailsToGA(res.data);

                const {items} = res.data;
                const filteredItemsByCustomFields = items.filter(filterByCustomField);

                const updateImages = filteredItemsByCustomFields.map(item => {
                    if (item?.images?.default?.url) {
                        let imageUrl = item.images.default.url;
                        if (USE_NEW_CDN) imageUrl = imageUrl.replace(OLD_SHARED_CDN_PATH, NEW_SHARED_CDN_PATH);

                        if (imageUrl.includes('{BRAND}')) {
                            imageUrl = imageUrl.replace('{BRAND}', SHARED_CDN_BRAND_ID);
                        }

                        item.images.default.url = imageUrl;
                    }
                    return item;
                });

                const specialEventPromoMastergroup = updateImages.filter(p => p.internalName.toUpperCase().includes(SPECIAL_EVENT_PROMO_MASTERGROUP)).sort(compare);
                let specialEventPromoBonuses = updateImages.filter(p => p.internalName.toUpperCase().includes(SPECIAL_EVENT_PROMO_PREFIX)).sort((a, b) => {
                    let aDay = a.internalName.substring(a.internalName.toUpperCase().indexOf(SPECIAL_EVENT_PROMO_PREFIX) + SPECIAL_EVENT_PROMO_PREFIX.length, a.internalName.toUpperCase().indexOf(SPECIAL_EVENT_PROMO_PREFIX) + SPECIAL_EVENT_PROMO_PREFIX.length + 2);
                    let bDay = b.internalName.substring(b.internalName.toUpperCase().indexOf(SPECIAL_EVENT_PROMO_PREFIX) + SPECIAL_EVENT_PROMO_PREFIX.length, b.internalName.toUpperCase().indexOf(SPECIAL_EVENT_PROMO_PREFIX) + SPECIAL_EVENT_PROMO_PREFIX.length + 2);
                    let cleanA = Number(aDay.replace('_', ''));
                    let cleanB = Number(bDay.replace('_', ''));

                    return cleanA - cleanB;
                });

                const filteredBanners = updateImages.filter(p => p.internalName.includes(PROMO_PREFIXES.LOBBY_BANNER) && !p.internalName.includes(PROMO_PREFIXES.LOBBY_BANNER_ADDITIONAL)).sort(compare);
                const filteredAdditionalItems = updateImages.filter(i => i.internalName.includes(PROMO_PREFIXES.LOBBY_BANNER_ADDITIONAL)).sort(compare);
                const filteredRewards = updateImages.filter(i => (i.internalName.includes(PROMO_PREFIXES.REWARDS) || i.internalName.includes(PROMO_PREFIXES.REWARDS_INFO))
                    && !i.internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_PREFIX)).sort(compare);

                const VISIBLE_NONCLAIMABLE_BONUS_TYPES = ["Content"];

                const filteredPromotions = updateImages.filter(i => {
                    if ((i.internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_PREFIX) && i.canBeClaimed)
                        || i.internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_MASTERGROUP)
                        || i.internalName.includes(PROMO_PREFIXES.DD_MASTERGROUP)) {
                        // || i.internalName.includes(PROMO_PREFIXES.DD_MASTERGROUP)) {
                        return i;
                    }

                    if (i.internalName.startsWith(PROMO_PREFIXES.SPIN_THE_WHEEL_MASTERGROUP) ||
                        i.internalName.startsWith(PROMO_PREFIXES.SPIN_THE_WHEEL_PRIZE_CLAIMED)) {
                        return true;
                    }

                    if (!i.canBeClaimed && !VISIBLE_NONCLAIMABLE_BONUS_TYPES.includes(i.bonusType)) {
                        return false;
                    }

                    if (i.internalName.includes(PROMO_PREFIXES.LOBBY_BANNER) ||
                        i.internalName.includes(PROMO_PREFIXES.REWARDS) ||
                        i.internalName.includes(PROMO_PREFIXES.REWARDS_INFO) ||
                        (i.internalName.includes(PROMO_PREFIXES.DD_PRIZE) && !i.canBeClaimed && i.bonusType !== "Content")) {
                        return false;
                    }

                    return i;
                }).sort(compare);

                console.log(filteredPromotions)

                let banners = [...filteredAdditionalItems, ...filteredBanners];
                const newBanners = organizeBanners(banners);

                setValue(newBanners);

                let dispatchPayload = {newBanners, filteredPromotions, filteredRewards};

                if (specialEventPromoMastergroup && specialEventPromoBonuses) {
                    dispatchPayload.specialEventPromoMastergroup = specialEventPromoMastergroup[0];
                    dispatchPayload.specialEventPromoBonuses = specialEventPromoBonuses;
                }

                dispatchPromotionsUpdate(dispatchPayload);

                return true;
            } else {
                if (currentAttempt <= maxRetriesPerListPromotionsCall) {
                    setTimeout(() => listPromotions(currentAttempt), 30000);
                }
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {listPromotionsStatus: status, listPromotions, execute};
}

function useUsersPromotions() {
    const dispatch = useDispatch();
    const usersPromotionsPath = "authorizedPaths.promotions.history";
    const {execute, status, value, error} = useRedPill(usersPromotionsPath);

    const listUsersPromotions = async ({types = false, statuses = false} = {}) => {
        if (!IS_LOGGED_IN) return false;

        const promoStatuses = statuses ? `promoStatuses=${statuses}&` : '';
        const bonusTypes = types ? `bonusTypes=${types}&` : '';
        const res = await execute({url: `?${promoStatuses}${bonusTypes}`});

        try {
            if (res.code === 200) {
                const {items} = res.data;
                dispatch(updateUsersPromotionsList(items));

                return true;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    const maxRetriesPerListPromotionsCall = 2;
    const listUsersActivePromotions = async ({types = false, statuses = false} = {}, attempt = 0) => {
        if (!IS_LOGGED_IN) return false;
        let currentAttempt = attempt;

        const promoStatuses = statuses ? `promoStatuses=${statuses}&` : '';
        const bonusTypes = types ? `bonusTypes=${types}&` : '';
        const res = await execute({url: `?${promoStatuses}${bonusTypes}&take=20`});
        currentAttempt++;

        try {
            if (res.code === 200) {
                const {items} = res.data;

                const updatedItems = items.map(item => {
                    if (item?.images?.default?.url) {
                        let imageUrl = item.images.default.url;
                        if (USE_NEW_CDN) imageUrl = imageUrl.replace(OLD_SHARED_CDN_PATH, NEW_SHARED_CDN_PATH);

                        if (imageUrl.includes('{BRAND}')) {
                            imageUrl = imageUrl.replace('{BRAND}', SHARED_CDN_BRAND_ID);
                        }

                        item.images.default.url = imageUrl;
                    }
                    return item;
                });

                dispatch(updateUsersActiveNotifications(updatedItems));

                return true;
            } else {
                if (currentAttempt <= maxRetriesPerListPromotionsCall) {
                    setTimeout(() => listUsersActivePromotions({types, statuses}, currentAttempt), 30000);
                }
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {execute, status, listUsersPromotions, listUsersActivePromotions};
}

function useOptInPromotion() {
    const optInToPromotionPath = "authorizedPaths.promotions.opt-in";
    const {execute, status, value, error} = useRedPill(optInToPromotionPath);
    const {_getMyAccount} = useMyAccount();
    const {singleAccountUpdate, singlePromotionsUpdate} = _getMyAccount();

    const optInPromotion = async (promoCode, refreshAccountAndPromos = true, delaySubsequentCallsInMs = 0) => {
        const res = await execute({url: promoCode, method: 'POST'});

        try {
            if (res.code === 200) {
                if (delaySubsequentCallsInMs > 0) {
                    await sleep(delaySubsequentCallsInMs);
                }

                if (refreshAccountAndPromos) {
                    singleAccountUpdate();
                    singlePromotionsUpdate();
                }

                return true;
            }
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }

        return false;
    };

    return {optInPromotionStatus: status, optInPromotion};
}

function useBuyInPromotion() {
    const optInToPromotionPath = "authorizedPaths.promotions.opt-in";
    const {execute, status, value, error} = useRedPill(optInToPromotionPath);
    const {listPromotions} = useListPromotions();
    const {_getMyAccount} = useMyAccount();
    const {singleAccountUpdate} = _getMyAccount();

    const buyInPromotion = async (promoCode, buyInAmount) => {
        const res = await execute({url: promoCode, data: {buyInAmount}, method: 'POST'});

        try {
            if (res.code === 200) {
                listPromotions();
                singleAccountUpdate();

                return true;
            }
        } catch (e) {
            console.log(e);
        }

        return false;
    };

    return {buyInPromotionStatus: status, buyInPromotion};
}

const selectUnclaimedPromotions = state => state.promotions.list;
const selectUnclaimedPromotionsLoaded = state => state.promotions.isLoaded;
const selectActivePromotions = state => state.promotions.activePromotions;
const selectActivePromotionsLoaded = state => state.promotions.isActivePromotionsLoaded;
const selectUsersPromotions = state => state.promotions.history;
const selectUsersPromotionsLoaded = state => state.promotions.isHistoryLoaded;
const selectRewardsPromotions = state => state.ui.rewardsPromos.list;
const selectRewardsPromotionsLoaded = state => state.ui.rewardsPromos.isLoaded;

const _useUnclaimedPromotions = () => {
    const unclaimedPromotions = useSelector(selectUnclaimedPromotions);
    const unclaimedPromotionsLoaded = useSelector(selectUnclaimedPromotionsLoaded);

    return {
        unclaimedPromotions,
        unclaimedPromotionsLoaded
    };
};

const _useActivePromotions = () => {
    const activePromotions = useSelector(selectActivePromotions);
    const activePromotionsLoaded = useSelector(selectActivePromotionsLoaded);

    return {
        activePromotions,
        activePromotionsLoaded
    };
};

const _useUsersPromotions = () => {
    const usersPromotions = useSelector(selectUsersPromotions);
    const usersPromotionsLoaded = useSelector(selectUsersPromotionsLoaded);

    return {
        usersPromotions,
        usersPromotionsLoaded
    };
};

const _useRewardsPromotions = () => {
    const rewardsPromotions = useSelector(selectRewardsPromotions);
    const rewardsPromotionsLoaded = useSelector(selectRewardsPromotionsLoaded);

    return {
        rewardsPromotions,
        rewardsPromotionsLoaded
    };
};

const _useFilteredUnclaimedPromotions = () => {
    const {canClaimDailyDeal, isMemberOfDailyDealMastergroup} = useDailyDeal();
    const {canClaimFreeSpinsGame, freeSpinsGamePromo, isMemberOfFSGMastergroup} = useFreeSpinsGamification();

    const {unclaimedPromotions: _unclaimedPromotions, unclaimedPromotionsLoaded: _unclaimedPromotionsLoaded} = _useUnclaimedPromotions();

    const memoizedFilteredPromotions = useMemo(() => {
        if (!_unclaimedPromotionsLoaded) return _unclaimedPromotions;

        return _unclaimedPromotions.filter(promo => {
            const customFieldsFilterResult = filterByCustomField(promo);
            if (!customFieldsFilterResult) return false;

            if (promo.hasOwnProperty('internalName')) {
                let internalName = promo.internalName.toUpperCase();

                // Don't show unclaimable DD promotions
                if (internalName.includes(PROMO_PREFIXES.DD_PRIZE) && !promo.canBeClaimed) {
                    return false;
                }

                // Don't show mastergroup promo
                if (internalName.startsWith(PROMO_PREFIXES.DD_MASTERGROUP)) {
                    return false;
                }

                // If user hasn't claimed DD, don't show prizes
                if (canClaimDailyDeal && internalName.startsWith('DD_')) {
                    return false;
                }

                if (internalName.includes(PROMO_PREFIXES.DD_PRIZE)) {
                    if (!isMemberOfDailyDealMastergroup) {
                        return false;
                    }
                }

                if ((!canClaimFreeSpinsGame || !freeSpinsGamePromo) && internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_MASTERGROUP)) {
                    return false;
                }

                if (internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_PREFIX)) {
                    if (!isMemberOfFSGMastergroup) {
                        return false;
                    }
                }

                // If user hasn't claimed FS, don't show prizes
                if (canClaimFreeSpinsGame && internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_PREFIX)) {
                    return false;
                }

                // Don't show PYB options or mastergroup
                if ((internalName.includes(PROMO_PREFIXES.PYB_OPTION) && !internalName.includes(PROMO_PREFIXES.PYB_OPTION_ACTUAL)) || internalName.includes(PROMO_PREFIXES.PYB_MASTERGROUP)) {
                    return false;
                }

                // Don't show mastergroup promo
                if (internalName.startsWith(PREFIXES.SPIN_THE_WHEEL)) {
                    return false;
                }
            }

            return true;
        })
    }, [_unclaimedPromotions, _unclaimedPromotionsLoaded]);

    return {
        unclaimedPromotions: memoizedFilteredPromotions,
        unclaimedPromotionsLoaded: _unclaimedPromotionsLoaded
    }
};

export const useDailyDeal = () => {
    const {unclaimedPromotions, unclaimedPromotionsLoaded} = _useUnclaimedPromotions();

    const canClaimDailyDeal = useMemo(() => {
        if (!unclaimedPromotionsLoaded) return false;

        return unclaimedPromotions.find(promo => {
            try {
                const internalName = promo.internalName.toUpperCase();
                return IS_DEV || (internalName.startsWith(PROMO_PREFIXES.DD_MASTERGROUP) && promo.canBeClaimed);
            } catch (err) {
                console.log(err);
            }
        })
    }, [unclaimedPromotions, unclaimedPromotionsLoaded])

    const dailyDealPromo = useMemo(() => {
        if (!unclaimedPromotionsLoaded) return false;

        return unclaimedPromotions.find(promo => {
            try {
                const internalName = promo.internalName.toUpperCase();
                return internalName.startsWith('DD_') && !internalName.startsWith(PROMO_PREFIXES.DD_MASTERGROUP);
            } catch (err) {
                console.log(err);
            }
        })
    }, [unclaimedPromotions, unclaimedPromotionsLoaded])

    const isMemberOfDailyDealMastergroup = useMemo(() => {
        if (!unclaimedPromotionsLoaded) return false;

        return unclaimedPromotions.some(p => p.internalName.includes(PROMO_PREFIXES.DD_MASTERGROUP));
    }, [unclaimedPromotions, unclaimedPromotionsLoaded])

    return {
        canClaimDailyDeal: canClaimDailyDeal && dailyDealPromo,
        dailyDealPromo,
        isMemberOfDailyDealMastergroup
    };
};
export const useFreeSpinsGamification = () => {
    const {unclaimedPromotions, unclaimedPromotionsLoaded} = _useUnclaimedPromotions();

    const canClaimFreeSpinsGame = useMemo(() => {
        if (!unclaimedPromotionsLoaded) return false;

        return unclaimedPromotions.find(promo => {
            try {
                const internalName = promo.internalName.toUpperCase();
                return IS_DEV || (internalName && internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_MASTERGROUP) && promo.canBeClaimed);
            } catch (err) {
                console.log(err);
            }
        })
    }, [unclaimedPromotions, unclaimedPromotionsLoaded])

    const freeSpinsGamePromo = useMemo(() => {
        if (!unclaimedPromotionsLoaded) return false;

        return unclaimedPromotions.find(promo => {
            try {
                const internalName = promo.internalName.toUpperCase();
                return internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_PREFIX);
            } catch (err) {
                console.log(err);
            }
        })
    }, [unclaimedPromotions, unclaimedPromotionsLoaded])

    const isMemberOfFSGMastergroup = useMemo(() => {
        if (!unclaimedPromotionsLoaded) return false;

        return unclaimedPromotions.some(p => p.internalName.includes(PROMO_PREFIXES.FREE_SPINS_GAME_MASTERGROUP))
    }, [unclaimedPromotions, unclaimedPromotionsLoaded])

    return {
        canClaimFreeSpinsGame: canClaimFreeSpinsGame && freeSpinsGamePromo,
        freeSpinsGamePromo,
        isMemberOfFSGMastergroup
    };
};

export const usePromotionsProps = () => {
    const {canClaimDailyDeal, dailyDealPromo} = useDailyDeal();
    const {canClaimFreeSpinsGame, freeSpinsGamePromo} = useFreeSpinsGamification();

    const {unclaimedPromotions, unclaimedPromotionsLoaded} = _useFilteredUnclaimedPromotions();
    const {activePromotions, activePromotionsLoaded} = _useActivePromotions();
    const {usersPromotions, usersPromotionsLoaded} = _useUsersPromotions();
    const {rewardsPromotions, rewardsPromotionsLoaded} = _useRewardsPromotions();

    const showSpecialEventPromoLink = useMemo(() => SPECIAL_EVENT_PROMO_ENABLED && (unclaimedPromotionsLoaded && unclaimedPromotions.some(p => p.internalName.toUpperCase() === SPECIAL_EVENT_PROMO_MASTERGROUP)), [unclaimedPromotions, unclaimedPromotionsLoaded]);

    return {
        activePromotions,
        activePromotionsLoaded,
        unclaimedPromotions,
        unclaimedPromotionsLoaded,
        usersPromotions,
        usersPromotionsLoaded,
        dailyDealPromo,
        canClaimDailyDeal: canClaimDailyDeal && dailyDealPromo,
        freeSpinsGamePromo,
        canClaimFreeSpinsGame: (canClaimFreeSpinsGame && freeSpinsGamePromo),
        rewardsPromotions,
        rewardsPromotionsLoaded,
        showSpecialEventPromoLink
    };
}

export default function usePromotions() {
    return {
        _get: useGetPromotion,
        _history: useUsersPromotions,
        _list: useListPromotions,
        _optIn: useOptInPromotion,
        _buyIn: useBuyInPromotion,
    }
};
