import {UPDATE_MESSAGE, UPDATE_MESSAGES_COUNT, UPDATE_MESSAGES_LIST} from "Redux/actions/messages";

let initialState = {
    list: null,
    messagesCount: null,
    unreadMessagesCount: null,
    isLoaded: false
};

const messagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MESSAGES_COUNT:
            return {...state, messagesCount: action.messageCount, unreadMessagesCount: action.unreadMessagesCount};
        case UPDATE_MESSAGES_LIST:
            return {...state, isLoaded: true, list: action.newMessagesList};
        case UPDATE_MESSAGE:
            let list = state.list;
            const updatedList = list.map(message => {
                if (message.id === action.newMessage.id) {
                    return action.newMessage;
                }

                return message;
            });

            return {...state, list: updatedList};
        default:
            return {...state};
    }
};

export default messagesReducer;
