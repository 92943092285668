// Action Types
export const UPDATE_PLAYER_LOYALTY_POINTS = "UPDATE_PLAYER_LOYALTY_POINTS";
export const UPDATE_LOYALTY_POINTS_CONFIG = "UPDATE_LOYALTY_POINTS_CONFIG";

// Action Creator
export const updatePlayerLoyaltyPoints = (data) => ({
    type: UPDATE_PLAYER_LOYALTY_POINTS,
    data,
});

export const updateLoyaltyPointsConfig = (config) => ({
    type: UPDATE_LOYALTY_POINTS_CONFIG,
    config,
});
