import {IS_MICROSOFT_TRACKING_ENABLED, MICROSOFT_TRACKING_PIXEL_ID} from "../setup/config";

const MICROSOFT_PIXEL_ID = MICROSOFT_TRACKING_PIXEL_ID;
const MICROSOFT_TRACKING_ENABLED = MICROSOFT_PIXEL_ID && IS_MICROSOFT_TRACKING_ENABLED;

export function initialiseMicrosoftTracking() {
    if (!MICROSOFT_TRACKING_ENABLED) return;

    (function (w, d, t, r, u) {
        var f, n, i;
        w[u] = w[u] || [], f = function () {
            var o = {ti: MICROSOFT_PIXEL_ID, enableAutoSpaTracking: true};
            o.q = w[u], w[u] = new UET(o), w[u].push("pageLoad")
        },
            n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {
            var s = this.readyState;
            s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)
        },
            i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)
    })(window, document, "script", "//bat.bing.com/bat.js", "uetq");
}

export function sendMicrosoftFTD({value, currency}) {
    if (!MICROSOFT_TRACKING_ENABLED) return;

    window.uetq = window.uetq || [];
    window.uetq.push("event", "purchase", {revenue_value: value, currency: currency});
}