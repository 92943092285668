import {HELP_CENTER_URL, IS_LOGGED_IN} from "Setup/config";
import {sendGA4Event} from "Helpers/gaEvents";

const siteId = window.MORPHEUS.core.brandConfig.brandTheme;

const GADictionary = {
    bb: "UA-11176070-1",
    bd: "UA-68967945-1",
    cd: "UA-65178797-1",
    cf: "UA-200344263-1",
    db: "UA-75582742-1",
    gb: "UA-37935519-1",
    rb: "UA-75530205-1",
    l247: "UA-101876639-1",
};

export const getGASiteId = () => {
    if (GADictionary.hasOwnProperty(siteId)) {
        return GADictionary[siteId];
    }

    return false;
};

const GA4Dictionary = {
    bb: "G-DRPC3KD3G7",
    bd: "G-6RRHGP9PCS",
    cd: "G-2N3H3HVSET",
    cf: "G-E0LLMFTKX1",
    db: "G-58961XWXS5",
    gb: "G-V6TJVJZ3NV",
    rb: "G-P95C6K9NQ2",
    l247: "G-0180JPDZKK",
};

export const getMeasurementId = () => {
    if (GA4Dictionary.hasOwnProperty(siteId)) {
        return GA4Dictionary[siteId];
    }

    return false;
};

const siteCodeDictionary = {
    fantasticouk: "Casino Fantastico",
    butlersuk: "Butlers Bingo",
    dottyuk: "Dotty Bingo",
    diamonduk: "Bingo Diamond",
    glossyuk: "Glossy Bingo",
    rosyuk: "Rosy Bingo",
    luckyuk: "Lucky 247",
    coduk: "Casino of Dreams",
};

const expressWithdrawalsArticleIdDictionary = {
    bb: "4403793792785",
    bd: "4403793709585",
    cd: "4403793888401",
    cf: "4403793835281",
    db: "4403793926545",
    gb: "360019973778",
    rb: "4403785910673",
    l247: "4403794314385",
};

export const getExpressWithdrawalsUrl = () => {
    if (expressWithdrawalsArticleIdDictionary.hasOwnProperty(siteId)) {
        const articleId = expressWithdrawalsArticleIdDictionary[siteId];
        return `${HELP_CENTER_URL}hc/en-us/articles/${articleId}-What-is-Express-Withdrawals-`;
    }

    return false;
};

export const getBrandNameFromSiteCode = siteCode => {
    if (siteCodeDictionary.hasOwnProperty(siteCode)) {
        return siteCodeDictionary[siteCode];
    }

    console.log(`Could not get siteCode from ${siteCode}`);

    return false;
};

export const trackGameLaunchEvent = (gameId, gameCategory = "Uncategorized") => {
    if (IS_LOGGED_IN) {
        sendGA4Event('launch_game', {
            game_launched_from: gameCategory,
            game_id: gameId ?? "",
        });
    }
}

export const sanitizeUrl = (url) => {
    try {
        const blockedParamsList = ["firstname", "lastname", "email"];
        const replaceWith = '[REDACTED]';

        return url.replace(/((\?)|&)([^#&=]+)(?:=([^#&]*))?/g, function (input, delim, qmark, key, val) {
            if (-1 === blockedParamsList.indexOf(key.toLowerCase()))
                return input;
            else return replaceWith ? delim + key + '=' + replaceWith : qmark || '';
        }).replace(/\?&*$|(\?)&+/, '$1');
    } catch (e) {
        return url;
    }
}
