import React from 'react';
import {GenericPageContainer} from "Pages/GenericInfoPages/GenericPage";
import {Typography} from "@material-ui/core";
import * as Sentry from "@sentry/react";
import {connect} from "react-redux";
import {closeAllModals} from "Redux/actions/ui";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            errorInfo: null
        };
    }

    componentDidCatch(error, errorInfo) {
        // // Catch errors in any child components and re-renders with an error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });

        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (!React.Children.count(this.props.children)) return null;

        if (this.state.error) {
            // console.log('-------------');
            // console.log("%cComponent Error", "font-weight: bold");
            // console.log(this.state.error);
            // console.log('-------------');

            if (this.props.hideOnError) {
                return null;
            }

            if (this.props.doNotRenderModalsWhenError) {
                this.props.closeAllModals();
                return null;
            }

            if (this.props.isRoute) {
                return (
                    <GenericPageContainer>
                        <Typography>There was a problem loading this page. Please try again later.</Typography>
                    </GenericPageContainer>
                );
            }

            // Render the component but pass a hasError prop
            return React.Children.map(this.props.children, child => React.cloneElement(child, {hasError: true, error: this.state.error}));

        }
        // component normally just renders children
        return this.props.children;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeAllModals: () => dispatch(closeAllModals())
    };
};

export default connect(state => ({}), mapDispatchToProps)(ErrorBoundary);

// export default ErrorBoundary;
