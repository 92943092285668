import {useSelector} from "react-redux";
import axios from "axios";
import {checkForNestedRoute, ROUTE_PARENT} from "Setup/routes";
import {useAsync} from "Hooks/useAsync";
import {IS_STAGING} from "Setup/config";

export function useRedPill(urlRoute = "", method = "GET", overrideURL = "") {
    // Get tokens from the store and check for API route
    const {session, API_URL} = useSelector(state => {
        let API_URL = urlRoute.length ? checkForNestedRoute(state, ...`${ROUTE_PARENT}.${urlRoute}`.split('.')) : false;

        if (overrideURL.length) {
            API_URL = overrideURL;
        }

        return {
            API_URL,
            session: state.morpheus.session
        }
    });

    // Check if we have the RedPill headers available to us.
    // These are required to make calls when logged in.
    const setRedPillHeaders = (additionalHeaders) => {
        let redPillHeaders = {...additionalHeaders}

        if (session.hasOwnProperty('store')) {
            redPillHeaders['X-RedPill-Store'] = session.store;

            if (session.hasOwnProperty('broker')) {
                redPillHeaders['X-RedPill-Broker'] = session.broker;

                if (session.hasOwnProperty('authenticator')) {
                    redPillHeaders['X-RedPill-Authenticator'] = session.authenticator;
                }
            }
        }

        return redPillHeaders;
    }

    const fetchData = async ({url = "", method = "GET", data = {}, config = {}, additionalHeaders = {}} = {}) => {
        if (!API_URL) {
            if (IS_STAGING) {
                alert(`${urlRoute} is unavailable. Check whether the specified API route exists.`);
            }
            // return false;
        } else {
            if(url.length) {
                url = `${API_URL}${url}`
            }else{
                url = `${API_URL}`;

                if(API_URL.slice(-1) === '/') {
                    url = url.slice(0, -1);
                }
            }
        }

        try {
            let headers = setRedPillHeaders(additionalHeaders);

            // @ts-ignore
            return axios({url, method, data, headers, config})
                .then((res) => {
                    return res.data;
                })
                .catch((error) => {
                    //sendExceptionToSentry(error);

                    if (error.response) {
                        if (error.response.hasOwnProperty('data') && error.response.data) {
                            // setResponse(error.response.data)
                        }

                        // if (error.response.data && error.response.data.hasOwnProperty('redirect') && error.response.data.redirect) {
                        //     const {redirect} = error.response.data;
                        //     console.log(redirect)
                        //     // window.location = redirect;
                        // }

                        // Request made and server responded
                        // console.error(error.response.data);
                        // console.error(error.response.status);
                        // console.error(error.response.headers);

                        return error.response.data;
                    } else if (error.request) {
                        // The request was made but no response was received
                        // console.error(error.request);
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        // console.error('Error', error.message);
                    }
                })
        } catch (e) {
            //sendExceptionToSentry(e);
            console.log(e);
        }
    };

    const {execute, status, value, error} = useAsync(params => fetchData(params));

    return {API_URL, execute, status, value, error};
}
