// Action Types
export const UPDATE_PLAYER_CLAIMS = "UPDATE_PLAYER_CLAIMS";
export const UPDATE_PLAYER_CLAIMS_STATUS = "UPDATE_PLAYER_CLAIMS_STATUS";
export const UPDATE_AVAILABLE_CLAIMS = "UPDATE_AVAILABLE_CLAIMS";
export const UPDATE_AVAILABLE_CLAIMS_STATUS = "UPDATE_AVAILABLE_CLAIMS_STATUS";

// Action Creator
export const updatePlayerClaims = (newPlayerClaims) => ({
    type: UPDATE_PLAYER_CLAIMS,
    newPlayerClaims,
});

export const updatePlayerClaimsStatus = (status, error) => ({
    type: UPDATE_PLAYER_CLAIMS_STATUS,
    status,
    error
});

export const updateAvailableClaims = (newAvailableClaims) => ({
    type: UPDATE_AVAILABLE_CLAIMS,
    newAvailableClaims,
});

export const updateAvailableClaimsStatus = (status, error) => ({
    type: UPDATE_AVAILABLE_CLAIMS_STATUS,
    status,
    error
});
