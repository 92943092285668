export const isValidJSON = str => {
    try {
        let test = JSON.parse(str);

        if (test && typeof test === 'object') {
            return true;
        }
    } catch (e) {
        //sendExceptionToSentry(e);
        return false;
    }

    return false;
};

export const getValueFromStorage = (key, storage = "sessionStorage") => {
    const s = storage === "sessionStorage" ? window.sessionStorage : window.localStorage;

    const isStorageAvailable = () => {
        const test = 'test';
        try {
            s.setItem(test, test);
            s.removeItem(test);
            return true;
        } catch (e) {
            //sendExceptionToSentry(e);
            return false;
        }
    };

    if (isStorageAvailable(s)) {
        if (s.getItem(key) === null) {
            console.log(`Could not find ${key} in ${storage}`);
            return null;
        }

        try {
            return JSON.parse(s.getItem(key));
        } catch (e) {
            //sendExceptionToSentry(e);
            return null;
        }
    }
};
